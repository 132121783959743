import useIntervalWhen from "@rooks/use-interval-when";
import { signOut } from "next-auth/client";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import { Image } from "primereact/image";
import { Menubar } from "primereact/menubar";
import { MenuItem, MenuItemOptions } from "primereact/menuitem/menuitem";
import React, { useEffect, useState } from "react";
import { useSession } from "../../functions/useSession";
import { logger } from "../../logger/logger";
import { AWSRumEventTypeEnum, recordEvent } from "../../public/scripts/rum";
import { ClinicianApi } from "../../types/clinician-api";
import { EpisodeDosespotUpdateRequest } from "../../types/emr-api";
import { DosespotModal } from "../DoseSpotModal";

export const NavigationBar = () => {
    interface Clinician {
        dosespotId?: string | null;
        headshot?: string | null;
        guid?: string | null;
    }

    interface ExtendedUser {
        name?: string | null;
        email?: string | null;
        image?: string | null;
        clinician?: Clinician | null;
    }

    const [session, loading] = useSession();
    const sessionUser = session?.user as ExtendedUser;

    const [doseSpotModalState, setDoseSpotModalState] = useState(false);
    const [doseSpotErrorURL, setDoseSpotErrorURL] = useState("");
    const [notificationCount, setNotificationCount] = useState(0);

    const getDoseSpotNotificationCounts = async (): Promise<number> => {
        const client = new ClinicianApi();
        try {
            const payload: EpisodeDosespotUpdateRequest = {
                clinicianDosespotId: sessionUser.clinician ? sessionUser.clinician.dosespotId : null,
                clinicId: null,
            };
            const responseData = await client.episode.episodeDosespotRefillsErrorsCountsUpdate(payload);
            const notifications = responseData.data as any;
            return (
                notifications.PendingPrescriptionsCount +
                notifications.PendingRxChangeCount +
                notifications.RefillRequestsCount +
                notifications.TransactionErrorsCount
            );
        } catch (err) {}
        return 0;
    };

    const fetchRefillErrorURL = async (): Promise<string> => {
        const client = new ClinicianApi();
        try {
            const payload: EpisodeDosespotUpdateRequest = {
                clinicianDosespotId: sessionUser.clinician ? sessionUser.clinician.dosespotId : null,
                clinicId: null,
            };
            const responseData = await client.episode.episodeDosespotRefillsErrorsUrlUpdate(payload);
            return responseData.data as any;
        } catch (err) {}
        return "";
    };

    useEffect(() => {
        getDoseSpotNotificationCounts().then((count) => setNotificationCount(count));
        fetchRefillErrorURL().then((url) => setDoseSpotErrorURL(url));
    }, []);
    useIntervalWhen(getDoseSpotNotificationCounts, 600000, true);

    const menubarModel = [
        {
            label: "My Completed Consults",
            url: "/completedConsults",
        },
        {
            label: "Resources",
            url: "https://steadymdinc.sharepoint.com/sites/SMDCKB/SitePages/ClinicianKBHome.aspx?csf=1&web=1&e=8HsNFV",
            target: "_blank",
        },
        {
            template: () => {
                return (
                    <a href="#" role="menuitem" className="p-menuitem-link">
                        <i
                            className="pi pi-bell p-mr-4 p-overlay-badge"
                            style={{ fontSize: "1.5rem" }}
                            onClick={(e) => {
                                e.preventDefault();
                                setDoseSpotModalState(true);
                            }}
                        >
                            {notificationCount > 0 ? <Badge value={notificationCount} size="normal" severity="danger"></Badge> : <></>}
                        </i>
                    </a>
                );
            },
        },
        {
            template: (item: MenuItem, options: MenuItemOptions) => {
                return (
                    <div className="px-3 py-2" style={{ cursor: "pointer" }}>
                        {sessionUser?.clinician?.headshot ? (
                            <div onClick={options.onClick}>
                                <Image src={`${sessionUser?.clinician?.headshot}`} className={"clinician-headshot"} />
                            </div>
                        ) : (
                            <Avatar icon={"pi pi-user"} shape={"circle"} onClick={options.onClick} />
                        )}
                    </div>
                );
            },
            items: [
                {
                    // This can be useful for the future
                    label: "Take Break",
                    command: () => {
                        const client = new ClinicianApi();
                        client.clinician
                            .clinicianTakeBreakCreate()
                            .catch((err) => logger.error(err))
                            .finally(() => {
                                signOut({ callbackUrl: "/auth/signin", redirect: false });
                                if (sessionUser) {
                                    recordEvent(AWSRumEventTypeEnum.ClinicianSignOut, { clinicianGuid: sessionUser?.clinician?.guid });
                                }
                            });
                    },
                },
                {
                    label: "Sign out",
                    command: () => {
                        signOut();
                        if (sessionUser) {
                            recordEvent(AWSRumEventTypeEnum.ClinicianSignOut, { clinicianGuid: sessionUser?.clinician?.guid });
                        }
                    },
                },
            ],
        },
    ];

    if (!session && !loading) {
        location.href = "/auth/signin";
    }

    return (
        <>
            {session && !loading && (
                <Menubar
                    model={menubarModel}
                    start={
                        <div onClick={() => (window.location.href = "/")} style={{ cursor: "pointer" }}>
                            <Image height="32" src={"/logo2.png"} alt={"logo"} />
                        </div>
                    }
                    className={"flex justify-content-between w-full z-1 fixed"}
                ></Menubar>
            )}
            <DosespotModal
                isOpen={doseSpotModalState}
                url={doseSpotErrorURL}
                toggle={async () => {
                    getDoseSpotNotificationCounts();
                    setDoseSpotModalState(!doseSpotModalState);
                }}
            ></DosespotModal>
        </>
    );
};
